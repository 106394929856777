import React from "react";
import { Nav, Navbar } from "react-bootstrap";

const Navs = () => (
  <Navbar expand="md" className="nav-main">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="text-center">
        <Nav.Link className="text-body" href="/">トップ</Nav.Link>
        <Nav.Link className="text-body" href="/maintenance/">太陽光発電メンテナンス</Nav.Link>
        <Nav.Link className="text-body" href="/tochi/">土地募集</Nav.Link>
        <Nav.Link className="text-body" href="/works/">施工実例</Nav.Link>
        <Nav.Link className="text-body" href="/online/">オンライン相談</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
export default Navs;
