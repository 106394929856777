import React from "react";
import Helmet from "react-helmet";

const Meta = (props) => (
  <Helmet
    htmlAttributes={{
      lang: "ja",
    }}
    title={(props.title ? props.title + " | " : "") + "平安コーポレーション 太陽光発電事業部 | 三重県名張市・伊賀市・宇陀市を中心に全国に対応"}
    meta={[
      {
        name: "description",
        content: props.description || "平安コーポレーションは三重県名張市・伊賀市・宇陀市を中心に全国に対応している太陽光発電の施工・設置を行っている企業です。",
      },
    ]}
  />
);

export default Meta;
