import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Footer = () => (
  <footer id="footer" className="text-center">
    <Link to="#" className="totop">
      <FontAwesomeIcon className="fai" icon={faChevronUp} />
    </Link>
    <Container>
      <Link to="/" className="d-block">
        <StaticImage className="foot-logo" alt="" src="../images/logo_foot.png" />
      </Link>
      <a href="https://www.heian-corp.jp/" className="d-block mt-3" target="_blank" rel="noreferrer">
        コーポレートサイトへ
      </a>
      <small className="d-block text-white copyright">©2021 heian-solarpower</small>
    </Container>
  </footer>
);
export default Footer;
